import {breakpoints, articleHalfPageBannersShowBreakpoints, jpFrontpageBreakpoints} from '../config';

export default abstract class LayoutHelper {
    public static isUserOnMobile(width: number): boolean {
        return width < breakpoints.lg;
    }

    public static isUserOnDesktopXL(width: number): boolean {
        return width >= breakpoints.xl;
    }

    public static canShowSectionHalfPage160(width: number): boolean {
        return width >= breakpoints.xl && width < breakpoints.xxl;
    }

    public static canShowSectionHalfPage300(width: number): boolean {
        return width >= breakpoints.xxl;
    }
    public static canShowJpFrontpageTopflowHalfPage(width: number): boolean {
        return width >= jpFrontpageBreakpoints.md
    }
    public static canShowJpFrontpageFullGridHalfPage160(width: number): boolean {
        return width >= jpFrontpageBreakpoints.xl && width < jpFrontpageBreakpoints.xxl;
    }
    public static canShowJpFrontpageFullGridHalfPage300(width: number): boolean {
        return width >= jpFrontpageBreakpoints.xxl;
    }

    public static canShowArticeHalfpageHeight(height: number): boolean {
        return height >= articleHalfPageBannersShowBreakpoints.height;
    }

    public static canShowArticleHalfPage160(width: number): boolean {
        return width >= articleHalfPageBannersShowBreakpoints.halfPage160 && width < articleHalfPageBannersShowBreakpoints.halfPage300;
    }

    public static canShowArticleHalfPage300(width: number): boolean {
        return width >= articleHalfPageBannersShowBreakpoints.halfPage300;
    }
}

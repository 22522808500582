import {Controller} from "../../../../../jp-assets/node_modules/stimulus"
import {application} from "../../../../../jp-assets/src/ts/stimulus";
import {createAnalytics} from "../../../../../jp-assets/node_modules/jppol-audio-platform-web-sdk"

enum Vendors {
    Facebook = 'facebook',
    Google = 'google',
    Vimeo = 'vimeo',
    Youtube = 'youtube',
    Twitter = 'twitter',
}

export default class EmbedController extends Controller {
    vendor: string = this.element.getAttribute("data-toolbox-vendor")!
    htmlElement: HTMLElement = this.element as HTMLElement

    connect() {
        window.addEventListener("load", event => {
            const omnyPodcast: HTMLIFrameElement = this.element.querySelector('iframe[src*=omny]')

            if (omnyPodcast != null) {
                const brand: string = window["commonJpData"].site == "finans.dk" ? "Finans" : "JyllandsPosten"
                const analytics = createAnalytics({
                    apiKey: "cdcszxG3ofKvI9J4wYOwRncjS2Lysqmp",
                    os: null,
                    device_model: null,
                    org: "jyllandsposten",
                    os_version: null,
                    client: window["commonJpData"].client,
                    env: "production",
                    client_version: null,
                    brand: brand,
                    user_agent: window.navigator.userAgent
                })
                analytics.createEmbedSession(omnyPodcast, {
                    was_restricted: window["commonJpData"].pageRestricted,
                    article_id: window["commonJpData"].contentId.toString(),
                    referrer: window.document.referrer,
                    referrer_id: null,
                    sso_id: window["commonJpData"].userId,
                    anon_id: window["commonJpData"].anonId,
                    auth_reason: window["commonJpData"].authorizationMethod
                })
            }


            const iframe: HTMLElement = this.element.querySelector("iframe")!
            const blockquote: HTMLElement = this.element.querySelector("blockquote")!

            if (iframe) {
                if (this.getVendor(Vendors.Youtube) ||
                    this.getVendor(Vendors.Google) ||
                    this.getVendor(Vendors.Vimeo)) {
                    this.setAspectRatio(iframe)
                }

                const ritzauFVIframes = this.element.querySelectorAll('iframe[src*="fv.ritzau"]')
                if (ritzauFVIframes.length > 0) {
                    for (let i = 0; i < ritzauFVIframes.length; i++) {
                        this.appendRitzauResizeScript(ritzauFVIframes[i].id)
                    }
                }
            }

            if (blockquote) {
                if (this.getVendor(Vendors.Twitter)) {
                    this.setAspectRatio(blockquote)
                }
            }

            this.element.classList.add("c-embed--loaded");
        })

        if (this.getVendor(Vendors.Facebook)) {
            this.setFacebookSDK()
        }
    }

    private getVendor(vendor: string) {
        return this.vendor == vendor ||
            this.element.querySelectorAll(`iframe[src*="${vendor}"]`).length > 0 ||
            this.element.querySelectorAll(`blockquote[class*="${vendor}"]`).length > 0
    }

    private setAspectRatio(media: HTMLElement) {
        // * This is intended for elements
        // * that are position absolute
        const width = +media.getAttribute("width")!;
        const height = +media.getAttribute("height")!;

        // * Set aspect ratio
        if (height > 0 && width > 0) {
            const aspectRatio = height / width;
            this.htmlElement.style.paddingBottom = aspectRatio * 100 + "%";
        }

        // * Remove height and width
        media.removeAttribute("height");
        media.removeAttribute("width");
        media.removeAttribute("min-width");
        media.removeAttribute("max-width");

        media.style.height = "";
        media.style.width = "";
        media.style.minWidth = "";
        media.style.maxWidth = "";
    }

    private setFacebookSDK() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';

        document.head.appendChild(script);
    }

    private appendRitzauResizeScript(iframeId: string) {
        var script = document.createElement('script');
        script.style.display = "none";
        script.classList.add("consent-independent");
        script.text = `if (window.addEventListener) { window.addEventListener('message', handleMessage, false); }
            else if (window.attachEvent) { window.attachEvent('onmessage', handleMessage);}function handleMessage(event)
            { if (event.data == null) return; var data = event.data; if (typeof data == 'string'){
            try { data = JSON.parse(data);console.log('fvrzData' + data);} catch (err) {} }
            if (data.action != undefined && data.action == 'iframe_resize' && data.targetFrame == '${iframeId}')
            { document.getElementById(data.targetFrame).style.height = data.data + 'px';}};`
        this.element.appendChild(script);
    }
}

application.register("embed", EmbedController);

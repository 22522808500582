import { application } from "../../../../../../jp-assets/src/ts/stimulus"
import RetryUntilDefined from "../../../../../../jp-assets/src/ts/util/RetryUntilDefined"
import { Controller } from "../../../../../../jp-assets/node_modules/stimulus"
import { IPopup } from "../../../popup/popup_controller"

class GetPopupResult {
    popupController: IPopup;
    popupElement: HTMLElement;

    constructor(popupController: IPopup, popupElement: HTMLElement){
        this.popupController = popupController;
        this.popupElement = popupElement;
    }
}

class GiveButtonController extends Controller {
    popupControllerReadyPromise: Promise<GetPopupResult> = RetryUntilDefined.asPromise(10000, () => this.getPopup(this))

    connect() {}

    private getPopup(controller: GiveButtonController): GetPopupResult {
        let popupElement;
        if (controller.element.getAttribute("data-has-access") === "true") {
            popupElement = document.querySelector("[data-popup-name='give']");
        } else {
            popupElement = document.querySelector("[data-popup-name='feature-unavailable']");
        }

        const popupController = controller.application.getControllerForElementAndIdentifier(popupElement, "popup");

        if(popupController){
            return new GetPopupResult(popupController, popupElement);
        } else {
            return undefined;
        }
    }

    openPopup() {
        this.popupControllerReadyPromise.then(result => {
            result.popupController.open()
        })
    }
}

export default GiveButtonController;

application.register("give-button", GiveButtonController);

import SingleEventBroadcast from "../util/SingleEventBroadcast";

export default class MainControllerCoordinationService {
    public static mainControllerInitialized: SingleEventBroadcast =
	new SingleEventBroadcast();

    public static mainControllerReady() {
        MainControllerCoordinationService.mainControllerInitialized.sendEvent();
    }

    public static mainControllerFailed() {
        MainControllerCoordinationService.mainControllerInitialized.noEvent();
    }

    public static awaitMainController(): Promise<void> {
        return MainControllerCoordinationService.mainControllerInitialized.awaitEvent();
    }
}

import { application } from "../../../../../jp-assets/src/ts/stimulus";
import { Controller } from "../../../../../jp-assets/node_modules/stimulus";
import { IPopup } from '../../popup/popup_controller';
import RetryUntilDefined from "../../../../../jp-assets/src/ts/util/RetryUntilDefined";
import {CookieService} from "../../../../../jp-assets/src/ts/services/CookieService";

class GetPopupInfo {
    popupController: IPopup;
    popupElement: HTMLElement;

    constructor(popupController: IPopup, popupElement: HTMLElement){
        this.popupController = popupController;
        this.popupElement = popupElement;
    }
}

const csrfToken = CookieService.getCookie('csrf_token');

class TopicsController extends Controller {
    static values = {
        tagid: String,
        isfollowing: Boolean,
        hasaccess: Boolean,
    };
    tagidValue: string;
    hasaccessValue: boolean;
    isfollowingValue: boolean;
    followUnfollowMethod: string;

    static targets = ["followUnfollowButton", "followUnfollowSpan"];
    followUnfollowButtonTarget: HTMLElement;
    followUnfollowSpanTarget: HTMLElement;
    topicsUrl: string;

    popupControllerReadyPromise: Promise<GetPopupInfo> = RetryUntilDefined.asPromise(10000, () => this.getPopup(this))
    popupErrorControllerReadyPromise: Promise<GetPopupInfo> = RetryUntilDefined.asPromise(10000, () => this.getErrorPopup(this))
    private getPopup(controller: TopicsController): GetPopupInfo {
        let popupElement = document.querySelector("[data-popup-name='my-topics-unavailable']") as HTMLElement;
        const popupController = controller.application.getControllerForElementAndIdentifier(popupElement, "popup");

        if(popupController){
            return new GetPopupInfo(popupController, popupElement);
        } else {
            return undefined;
        }
    }

    private getErrorPopup(controller: TopicsController): GetPopupInfo {
        let popupElement = document.querySelector("[data-popup-name='topics-error-popup']") as HTMLElement;
        const popupController = controller.application.getControllerForElementAndIdentifier(popupElement, "popup");

        if(popupController){
            return new GetPopupInfo(popupController, popupElement);
        } else {
            return undefined;
        }
    }

    connect() {
        if (this.hasaccessValue == true) {
            this.topicsUrl = `${window.location.origin}/api/v1/my-topics/${this.tagidValue}`;
            if (this.isfollowingValue == true) {
                this.followUnfollowMethod = "DELETE";
            } else {
                this.followUnfollowMethod = "POST";
            }
        }
    }

    toggle() {
        if (this.isfollowingValue == true) {
            this.isfollowingValue = false;
            this.followUnfollowMethod = "POST";
            this.followUnfollowSpanTarget.innerHTML = "Følg";
            this.followUnfollowButtonTarget.classList.remove("followingTopic");
            this.followUnfollowButtonTarget.classList.add("notFollowingTopic");
        } else {
            this.isfollowingValue = true;
            this.followUnfollowMethod = "DELETE";
            this.followUnfollowSpanTarget.innerHTML = "Følger";
            this.followUnfollowButtonTarget.classList.add("followingTopic");
            this.followUnfollowButtonTarget.classList.remove("notFollowingTopic");
        }
    }

    followUnfollow() {
        // Save the method before toggling
        const method = this.followUnfollowMethod
        if (this.topicsUrl && this.hasaccessValue == true) {
            fetch(this.topicsUrl, {
                method: method,
                headers: {
                    "Content-Type": "text/plain",
                    "Csrf-Token": csrfToken
                },
            })
                .then((response) => {
                    switch (response.status) {
                        case 200:
                        case 201:
                            this.toggle();
                            return response.text()
                        case 403:
                            this.openPopup()
                            break;
                        case 500:
                            this.openErrorPopup();
                            break;
                    }
                })
                .catch((error) => {
                    this.openErrorPopup();
                    console.error("Error:", error);
                });
        } else {
            this.openPopup();
        }
    }
    openPopup() {
        this.popupControllerReadyPromise.then(result => {
            result.popupController.open()
        })
    }

    openErrorPopup() {
        this.popupErrorControllerReadyPromise.then(result => {
            result.popupController.open()
        })
    }

}

export default TopicsController;

application.register("topics", TopicsController);

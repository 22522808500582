import {getMoreReadingListItems} from "../../service";
import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import {application} from "../../../../../../../jp-assets/src/ts/stimulus";
import IPopup from "../../../../popup/popup_controller";

export default class SaveArticlePaginationController extends Controller {

    static targets = ["more", "loadButton"]
    moreTarget: HTMLElement;
    loadButtonTarget: HTMLElement;
    loadedArticles: number;
    canLoadMore: boolean = true;
    errorPopupElement: HTMLElement;
    errorPopupController: IPopup;

    connect() {
        this.getErrorPopupController().catch(() => {
            this.getControllerAgainAfterTimeout(200)
        })
        //Count number of articles when page is loaded initially
        this.loadedArticles = document.getElementsByClassName("c-save-article-teaser").length
    }

    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getErrorPopupController());
    }

    // wait for all Stimulus controllers to load
    private getErrorPopupController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.errorPopupElement = document.querySelector("[data-popup-name='error-popup']")
            this.errorPopupController = this.application.getControllerForElementAndIdentifier(
                this.errorPopupElement,
                "popup") as IPopup

            if (this.errorPopupController) {
                resolve(this.errorPopupController)
            } else {
                reject()
            }
        })
    }

    loadMore() {
        if (this.canLoadMore){
            let resp: Promise<Response> = getMoreReadingListItems(this.loadedArticles)
            let section = document.querySelector(".c-laeseliste-wrapper__list")
            resp.then(response => {
                response.text().then(element => {
                    if (response.headers.get("X-Last-Page") === "True") {
                        this.moreTarget.innerHTML = "Du har ikke flere gemte artikler"
                        this.moreTarget.style.color = "#CCCCCC";
                        this.moreTarget.style.paddingLeft = "0px"
                        this.moreTarget.style.pointerEvents = "none";
                        this.loadButtonTarget.style.backgroundColor = "transparent"
                        this.canLoadMore = false;
                    }
                    let scrollPos = document.scrollingElement.scrollTop
                    let wrapper = document.createElement('div')
                    wrapper.innerHTML = element
                    let newPage = wrapper.firstElementChild as HTMLElement
                    this.loadedArticles += newPage.children.length
                    section.append(newPage)
                    //This is an implemented fix so the page doesn't jump when inserting new pages
                    document.scrollingElement.scrollTop = scrollPos
                })
            }).catch(error => {
                document.querySelector("#error-popup-content").innerHTML =
                    "Artiklerne i din læseliste kunne ikke indlæses"
                this.errorPopupController.open()
            })

        }

    }

    fadeIn(element: HTMLElement) {
        let op = 0.025;  // initial opacity
        element.style.display = 'block';
        let timer = setInterval(function () {
            if (op >= 1){
                clearInterval(timer);
            }
            element.style.opacity = `${op}`;
            element.style.filter = 'alpha(opacity=' + op * 100 + ")";
            op += op * 0.025;
        }, 3);
    }
}

application.register("save-article-pagination", SaveArticlePaginationController);

import { Application } from "stimulus"

export const application = Application.start();

export const importAll = (require: any) => {
    require.keys().forEach(require);
}

importAll(require.context('./', true, /.*_controller\.ts/));
importAll(require.context('../../../app/views/components', true, /.*_controller\.ts/));
importAll(require.context('../../../app/views/utility', true, /.*_controller\.ts/));

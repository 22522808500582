import FetchWithTimeoutClient from "../clients/FetchWithTimeoutClient";

export default interface ScriptLoaderService {
  new(fetchWithTimeoutClient: FetchWithTimeoutClient): ScriptLoaderService

  injectScript(url: URL, elementId: string): Promise<void>
}

export default class ScriptLoaderServiceImpl implements ScriptLoaderService {

  private readonly fetcher: FetchWithTimeoutClient;

  /**
   * Constructs a ScriptLoaderServiceImpl.
   */
  public constructor(fetchWithTimeoutClient: FetchWithTimeoutClient) {
    this.fetcher = fetchWithTimeoutClient;
  }

  /**
   * Injects a script into the DOM.
   * @param url - the script to inject.
   * @param elementId - the id to attach to the injected script tag.
   */
  public injectScript(url: URL, elementId: string): Promise<void> {

    const scriptNode: HTMLScriptElement = document.createElement('script');
    scriptNode.type = "application/javascript";
    scriptNode.src = url.toString();
    scriptNode.setAttribute('id', elementId);

    document.head.appendChild(scriptNode);

    return Promise.resolve();

  }

}

import {Context, Controller} from "stimulus"
import { EditorialFrontendService, IEditorialFrontendService } from '../services/EditorialFrontendService';
import { application } from "../stimulus";


export default class EditorialFrontendController extends Controller {

  private readonly editorialFrontendService: IEditorialFrontendService;


  public constructor(context: Context) {
    super(context);

    this.editorialFrontendService = new EditorialFrontendService(this.getAnonId(), this.getRelevanceSegments());
  }

  connect() {
    this.editorialFrontendService.connect();
  }

  private getAnonId(): string {
    const defaultValue = "00000000-0000-0000-0000-000000000000";
    return window.anonIdJson || defaultValue;
  }

  private getRelevanceSegments(): string[] {
    const defaultValue: string[] = [];
    if (!window.relevanceSegmentsJson) {
      return defaultValue;
    } else {
      return JSON.parse(window.relevanceSegmentsJson as string);
    }
  }
}

application.register("editorial-frontend", EditorialFrontendController);

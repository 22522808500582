import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../../../../jp-assets/src/ts/stimulus";

export interface ISaveArticleMessage extends Controller {
    messageAnimation?: (text: string) => void
 }

class SaveArticleMessageController extends Controller implements ISaveArticleMessage {

    messageAnimation(text: string) {
        const selectButton = document.querySelector(".c-save-article-button-message")
        const selectText = document.querySelector(".c-save-article-button-message__text") as HTMLElement
        selectButton.classList.add("c-save-article-button-message__show")
        selectText.innerText = text

        setTimeout(() => {
            selectButton.classList.remove("c-save-article-button-message__show")
        }, 2000)
    }
}

export default SaveArticleMessageController;

application.register("save-article-message", SaveArticleMessageController);

import { Controller } from "../../../../jp-assets/node_modules/stimulus";
import { application } from "../../../../jp-assets/src/ts/stimulus";

export default class InvestorController extends Controller {
    static targets: string[] = [
        "dropDown",
        "dropDownToggle",
        "loading",
        "stockTitle",
    ];

    dropDownTarget: HTMLElement;
    dropDownToggleTarget: HTMLElement;
    loadingTarget: HTMLElement;
    stockTitleTarget: HTMLElement;

    hasDropDownToggleTarget: Boolean;
    dataInsref: string = this.element.getAttribute("data-insref") || ""; // Prevent the value from being set to null

    connect(): void {
        if (this.hasDropDownToggleTarget) {
            this.dropDownToggleTarget.addEventListener("click", () => {
                this.dropDownTarget.classList.toggle("m--open");
            });
        }

        window.addEventListener("millistream-list-widget-ready", () => {
            let widgets: any = this.element.getElementsByClassName("c-investor-widget");
            widgets.forEach((widget) => {
                widget.classList.remove("m--loading");
            })
        });

        this.callApi(this.dataInsref, "name,marketplace").then((response) => {
            if (response.name === "") { 
                this.setStockTitle(response[0]["name"]);
            }
        });
        this.dropdownLogic();
    }

    setStockTitle(value: string) {
        this.stockTitleTarget.innerText = value;
    }

    dropdownLogic() {
        let dropdowns: any = this.element.getElementsByClassName(
            "c-investor__drop-down"
        );
        dropdowns.forEach((element) => {
            let title = element.getElementsByClassName(
                "c-investor__drop-down__title"
            )[0];
            let titleText = element.getElementsByClassName(
                "c-investor__drop-down__title-text"
            )[0];
            let items = element.getElementsByClassName(
                "c-investor__drop-down__item"
            );
            title.addEventListener("click", () =>
                element.classList.toggle("m--active")
            );
            element.addEventListener("mouseleave", () => 
                element.classList.remove("m--active")
            );
            items.forEach((item) => {
                item.addEventListener(
                    "click",
                    () => (titleText.innerText = item.innerText)
                );
            });
        });
    }

    async callApi(insref: string, fields: string) {
        const response = await fetch(
            `/investor/millistream-data/get-stock-info?insref=${insref}&fields=${fields}`,
            {
                method: "GET",
            }
        );
        let content: any;
        try {
            const data = await response.json();
            content = data;
        } catch (error) {
            content = console.error(error);
        }
        return content;
    }
}

application.register("investor", InvestorController);

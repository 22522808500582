export default class SingleEventBroadcast {
  private sub: Promise<void>;
  private pub: () => void;
  private annul: () => void;

  public sendEvent(): void {
    this.pub();
  }

  public noEvent(): void {
    this.annul();
  }

  public awaitEvent(): Promise<void> {
    return this.sub;
  }

  public constructor() {
    this.pub = this.annul = () => {
    }; // make compiler stop nagging
    this.sub = new Promise((resolve, reject) => {
      this.pub = resolve;
      this.annul = reject;
    });
  }
}

import {getReadingList} from "../give_share_save_buttons/service"


interface Article {
    contentId: number
}

type Articles = Article[]

// Match article ids from reading list and string
export function matchArticleId(articleId: string): Promise<boolean> {
    const id = parseInt(articleId);
    return getReadingList().then((articles: Articles) => {
        return !!articles.find(article => article.contentId === id);
    });
}

import { Controller } from "../../../../../jp-assets/node_modules/stimulus";
import { application } from "../../../../../jp-assets/src/ts/stimulus";
import { IPopup } from "../../popup/popup_controller";

import {
    getStatistics,
    validateToken,
    getShareToken,
} from "../give_share_save_buttons/service";

let articleId: number;
let rawLink: string;

const origin: string = window.location.origin;

declare global {
    interface Window {
        MSStream: any;
    }
}
class GiveArticlePopupController extends Controller {
    consoleErrorPrefix: string = "Give Article failed due to following error: ";

    static targets: string[] = [
        "link",
        "messenger",
        "email",
        "sharingAvailable",
        "sharingUnavailable",
        "counter",
        "limit",
        "linkCopied",
        "loading",
    ];
    static classes = ["active", "failure", "hidden"];

    textCopySuccess = "Kopieret til udklipsholder";
    textCopyFailure = "Kopiering mislykkedes";

    sharingAvailableTarget: HTMLElement;
    sharingUnavailableTarget: HTMLElement;
    counterTarget: HTMLElement;
    limitTarget: HTMLElement;
    linkCopiedTarget: HTMLElement;
    loadingTarget: HTMLElement;
    activeClass: string;
    failureClass: string;
    counterClass: string;
    counter: number;
    limit: number;
    token: string;
    buttonType: any;

    giveArticlePopupElement: HTMLElement;
    giveArticlePopupController: IPopup;

    genericErrorElement: HTMLElement;
    genericErrorController: IPopup;

    connectionErrorElement: HTMLElement;
    connectionErrorController: IPopup;

    shouldShowMessengerButton() {
        let userAgent: string = navigator.userAgent || navigator.vendor;
        if (
            /android/i.test(userAgent) ||
            (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
        ) {
            this.element
                .querySelector("[data-button-type='messenger']")
                .classList.remove("d-none");
        }
    }

    connect() {
        this.getControllers().catch((error) => console.error(this.consoleErrorPrefix + error));

        this.shouldShowMessengerButton();

        articleId = parseInt(this.element.getAttribute("data-article-id"));
        rawLink = origin + "/article" + articleId + ".ece";

        window.addEventListener("popup-open", (element: any) => {
            if (element.detail === "give") {
                this.setStatistics(articleId);
            }
        });

        window.addEventListener("popup-closed", (element: any) => {
            if (element.detail === "give") {
                // Reset popup state
                this.loadingTarget.classList.remove("d-none");
                this.sharingAvailableTarget.classList.add("d-none");
                this.sharingUnavailableTarget.classList.add("d-none");
            }
        });

        window.addEventListener("token-received", (element: any) => {
            if (element.detail.token !== undefined) {
                switch (element.detail.buttonType) {
                    case "messenger":
                        this.makeMessengerLink(element.detail.token, rawLink);
                        break;
                    case "email":
                        this.makeEmailLink(element.detail.token, rawLink);
                        break;
                    case "link":
                        this.makeLink(element.detail.token, rawLink);
                        break;
                    default:
                        console.error(this.consoleErrorPrefix + "Unknown button type");
                        this.genericErrorController.open();
                        break;
                }
            } else {
                console.error(this.consoleErrorPrefix + "The API returned no token");
                this.genericErrorController.open();
            }
        });
    }

    private getConnectionErrorController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.connectionErrorElement = document.querySelector(
                "[data-popup-name='give-connection-error-popup']"
            );
            if (this.connectionErrorElement) {
                this.connectionErrorController =
                    this.application.getControllerForElementAndIdentifier(
                        this.connectionErrorElement,
                        "popup"
                    );
                resolve(this.connectionErrorController);
            } else {
                reject(new Error("No popup controller found"));
            }
        });
    }
    private getGenericErrorController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.genericErrorElement = document.querySelector(
                "[data-popup-name='give-generic-error-popup']"
            );
            if (this.genericErrorElement) {
                this.genericErrorController =
                    this.application.getControllerForElementAndIdentifier(
                        this.genericErrorElement,
                        "popup"
                    );
                resolve(this.genericErrorController);
            } else {
                reject(new Error("No popup controller found"));
            }
        });
    }
    private getGiveArticleController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.giveArticlePopupElement = document.querySelector(
                "[data-popup-name='give']"
            );
            if (this.giveArticlePopupElement) {
                this.giveArticlePopupController =
                    this.application.getControllerForElementAndIdentifier(
                        this.giveArticlePopupElement,
                        "popup"
                    );
                resolve(this.giveArticlePopupController);
            } else {
                reject(new Error("No popup controller found"));
            }
        });
    }
    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getControllers());
    }

    // Wait for all Stimulus controllers to load
    private getControllers(): Promise<any> {
        return Promise.all([
            this.getConnectionErrorController(),
            this.getGenericErrorController(),
            this.getGiveArticleController(),
        ]).then((controllers: any[]) => {
            if (controllers.includes(null)) {
                return this.getControllerAgainAfterTimeout(200);
            } else {
                return controllers;
            }
        });
    }


    setStatistics(id: number) {
        getStatistics()
            .then((response) => {
                this.counter = response["giftsGiven"];
                this.limit = response["giftLimit"];
                this.updateCounter();

                // find article id in gifted content
                let giftedContent = response["giftedContent"];
                let articleFound = false;
                for (let i = 0; i < giftedContent.length; i++) {
                    if (giftedContent[i]["gift"]["contentId"] === id) {
                        articleFound = true;
                        break;
                    }
                }

                if ((this.counter >= this.limit) && (!articleFound)) {
                    this.sharingAvailableTarget.classList.add("d-none");
                    this.sharingUnavailableTarget.classList.remove("d-none");
                } else {
                    this.sharingAvailableTarget.classList.remove("d-none");
                }

                this.loadingTarget.classList.add("d-none");
            })
            .catch((error) => {
                console.error(this.consoleErrorPrefix + error);
                this.giveArticlePopupController.close();
                this.connectionErrorController.open();
            });
    }

    okButton() {
        this.giveArticlePopupController.close();
    }

    updateCounter() {
        this.counterTarget.innerHTML = this.counter.toString();
        this.limitTarget.innerHTML = this.limit.toString();
    }

    giveButtonClicked(button: any, id: number = articleId) {
        this.buttonType = button.currentTarget.getAttribute("data-button-type");
        if (!this.token) {
            getShareToken(id)
                .then((response) => {
                    this.token = response["validationToken"];
                })
                .finally(() => {
                    this.dispatchTokenReceivedEvent(
                        this.token,
                        this.buttonType
                    );
                    this.updateCounter();
                })
                .catch((error) => {
                    console.error(this.consoleErrorPrefix + error);
                    this.genericErrorController.open();
                });
        } else {
            this.dispatchTokenReceivedEvent(this.token, this.buttonType);
        }
    }
    dispatchTokenReceivedEvent(token: string, buttonType: string) {
        window.dispatchEvent(
            new CustomEvent("token-received", {
                detail: {
                    token: token,
                    buttonType: buttonType,
                },
            })
        );
    }

    makeLink(token: string, link: string) {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(link + "?shareToken=" + token)
                .then(() => {
                    this.linkCopiedTarget.classList.add(this.activeClass);
                    this.linkCopiedTarget.textContent = this.textCopySuccess;
                    setTimeout(() => {
                        this.linkCopiedTarget.classList.remove(
                            this.activeClass
                        );
                    }, 3000);
                });
        } else {
            this.linkCopiedTarget.classList.add(
                this.activeClass,
                this.failureClass
            );
            console.error(
                this.consoleErrorPrefix + "No clipboard support, cannot copy link. Maybe it is disabled in your browser?"
            );
            this.linkCopiedTarget.textContent = this.textCopyFailure;
        }
    }
    makeMessengerLink(token: string, link: string) {
        window.location.href =
            "fb-messenger://share?link=" + link + "?shareToken=" + token;
    }
    makeEmailLink(token: string, link: string) {
        window.location.href =
            "mailto:?subject=" +
            "Artikel til dig fra mig" +
            "&body=" +
            "Hej," +
            "%0D%0A" + // This is a new line
            "Jeg tror, denne artikel fra Jyllands-Posten vil være interessant for dig." +
            "%0D%0A%0D%0A" +
            encodeURIComponent(link) +
            "%3FshareToken%3D" +
            token +
            "%0D%0A%0D%0A" +
            "God læselyst";
    }
}

export default GiveArticlePopupController;

application.register("give-article-popup", GiveArticlePopupController);

export default class TimeoutPromise {

  /**
   * Starts a timed promise. If the timeout is reached then the abort action is called.
   * @param actionPromise - the action to fulfill.
   * @param abortAction - the action to call in case of timeout.
   * @param timeoutInMillis - the timeout limit in milliseconds.
   * @returns a promise with the requested content or a rejected promise in case of timeout or error.
   */
  public static start(actionPromise: Promise<any>, abortAction: () => void, timeoutInMillis?: number): Promise<any> {

    let timeoutId: number;
    const timeout = timeoutInMillis || 2000;

    function successFn(success: any) {
      return Promise.resolve(success)
    }

    function abortFn (error: Error) {
      abortAction();
      return Promise.reject(error)
    }

    function clearTimeoutFn() {
      timeoutId && clearTimeout(timeoutId)
    }

    const timeoutPromise = new Promise((_, reject) => {
      timeoutId =  window.setTimeout(() => {
        const err = new Error();
        err.name = "TimeoutPromiseTimeout";
        err.message = "TimeoutPromise timed out";
        reject(err);
      }, timeout);
    });

    return Promise.race([actionPromise, timeoutPromise])
        .then(successFn, abortFn)
        .finally(clearTimeoutFn);

  }
}

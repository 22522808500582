export enum SameSite {
    None = "None",
    Lax = "Lax",
    Strict = "Strict"
}

export class CookieService {
    public static getCookie(name: string): string {
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieList = decodedCookie.split(";");
        const nameWithPrefix = name + "=";

        for (let cookie of cookieList) {
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }

            if(cookie.indexOf(nameWithPrefix) == 0) {
                return cookie.substring(nameWithPrefix.length, cookie.length);
            }
        }

        return "";
    }

    public static setCookie(
        name: string,
        value: any,
        expires?: string,
        maxAge?: number,
        path?: string,
        domain?: string,
        secure?: boolean,
        sameSite?: SameSite
    ): void {
        const cookie = CookieService.generateCookieString(name, value, expires, maxAge, path, domain, secure, sameSite);
        document.cookie = cookie;
    }

    public static generateCookieString(
        name: string,
        value: any,
        expires?: string,
        maxAge?: number,
        path?: string,
        domain?: string,
        secure?: boolean,
        sameSite?: SameSite
    ): string {
        return name + "=" + value +
             (expires ? `;expires=${expires}` : "") +
             (maxAge != undefined ? `;max-age=${maxAge}` : "") +
             (path ? `;path=${path}` : "") +
             (domain ? `;domain=${domain}` : "") +
             (secure ? `;secure` : "") +
             (sameSite ? `;samesite=${sameSite}` : "");
    }
}

import {breakpoints} from '../../../../../../../jp-assets/src/ts/config';
import {ISaveArticleAnimation} from '../animation/save_article_animation_controller';
import {ISaveArticleMessage} from '../button/save_article_message_controller';
import {addItemToReadingList, deleteItemFromReadingList} from '../../service';
import {Application, Controller} from "../../../../../../../jp-assets/node_modules/stimulus"
import {application} from "../../../../../../../jp-assets/src/ts/stimulus";
import {IPopup} from '../../../../popup/popup_controller';
import {matchArticleId} from '../../helpers';
import saveArticleButtonControllerStatus from '../button/SaveArticleButtonControllerStatus';

export interface ISaveArticleButtonController {
    application: Application
    textTarget: HTMLElement
    iconSaveTarget: HTMLElement
    iconSavedTarget: HTMLElement
    iconCloseTarget: HTMLElement
    textAdd: string
    textAdded: string
    textRemove: string
}


class SaveArticleButtonController extends Controller implements ISaveArticleButtonController {
    textTarget: HTMLElement
    iconSaveTarget: HTMLElement
    iconSavedTarget: HTMLElement
    iconCloseTarget: HTMLElement
    activeClass: string;
    disabledClass: string;

    messageElement: HTMLElement;
    messageController: ISaveArticleMessage;

    animationElement: HTMLElement;
    animationController: ISaveArticleAnimation;

    popupElement: HTMLElement;
    popupController: IPopup;

    errorMessageElement: HTMLElement;
    errorMessageController: IPopup;

    static targets = ["text", "iconSave", "iconSaved", "iconClose"]
    static classes = ["active", "disabled"]
    static values = {
        id: String
    }

    idValue: string

    textAdd = "Gem på læseliste"
    textAdded = "Gemt på læseliste"
    textRemove = "Fjern fra læseliste"

    connect() {
        this.getControllers().then((hasControllers) => {
            // TODO: Window should be moved to root of project with Window.d.ts
            // @ts-ignore
            if (hasControllers && window.authState.subscription.hasSaveArticle) {
                this.setButtonStatus()
            }
        }).catch((error: Error) => {
            this.element.classList.add(this.disabledClass)
            console.error(error)
        })
    }

    private setButtonStatus(): void {
        // Check if article is in reading list
        matchArticleId(this.idValue).then((isInReadingList: boolean) => {
            if (isInReadingList) {
                this.element.classList.add(this.activeClass)
                saveArticleButtonControllerStatus.added(this)
            } else {
                saveArticleButtonControllerStatus.add(this)
            }
        }).catch(() => {
            this.element.classList.add(this.disabledClass)
        })
    }

    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getControllers());
    }

    // Wait for all Stimulus controllers to load
    private getControllers(): Promise<any> {
        return Promise.all([
            this.getMessageController(),
            this.getAnimationController(),
            this.getPopupController(),
            this.getErrorMessageController()
        ]).then((controllers: any[]) => {
            if (controllers.includes(null)) {
                return this.getControllerAgainAfterTimeout(200)
            } else {
                return controllers
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getMessageController(): Promise<ISaveArticleMessage> {
        return new Promise((resolve, reject) => {
            this.messageElement = document.querySelector("[data-controller='save-article-message']")
            if (this.messageElement) {
                this.messageController = this.application.getControllerForElementAndIdentifier(
                    this.messageElement,
                    "save-article-message")
                resolve(this.messageController)
            } else {
                reject(new Error("No saveArticleMessage controller found"))
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getAnimationController(): Promise<ISaveArticleAnimation> {
        return new Promise((resolve, reject) => {
            this.animationElement = document.querySelector("[data-controller='save-article-animation']")
            if (this.animationElement) {
                this.animationController = this.application.getControllerForElementAndIdentifier(
                    this.animationElement,
                    "save-article-animation")
                resolve(this.animationController)
            } else {
                reject(new Error("No saveArticleAnimation controller found"))
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getPopupController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.popupElement = document.querySelector("[data-popup-name='save']")
            if (this.popupElement) {
                this.popupController = this.application.getControllerForElementAndIdentifier(
                    this.popupElement,
                    "popup")
                resolve(this.popupController)

            } else {
                reject(new Error("No popup controller found"))
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getErrorMessageController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.errorMessageElement = document.querySelector("[data-popup-name='error-popup']")
            if (this.errorMessageElement) {
                this.errorMessageController = this.application.getControllerForElementAndIdentifier(
                    this.errorMessageElement,
                    "popup")
                resolve(this.errorMessageController)
            } else {
                reject(new Error("No popup controller found"))
            }
        })
    }

    private addOrDeleteReadingList() {
        this.element.classList.toggle(this.activeClass)

        if (!this.element.classList.contains(this.activeClass)) {
            deleteItemFromReadingList(this.idValue).then(() => {
                saveArticleButtonControllerStatus.add(this)
                this.messageController.messageAnimation("Artiklen er fjernet fra din læseliste")
            }).catch((error) => {
                console.error(error)
                this.errorMessageController.open()
            })
        } else {
            addItemToReadingList(this.idValue).then(() => {
                saveArticleButtonControllerStatus.added(this)
                this.animationController.addAnimation()
            }).catch((error) => {
                console.error(error)
                this.errorMessageController.open()
            })
        }
    }

    mouseOver() {
        if (window.innerWidth > breakpoints.lg) {
            if (this.element.classList.contains(this.activeClass)) {
                saveArticleButtonControllerStatus.remove(this)
            } else {
                saveArticleButtonControllerStatus.add(this)
            }
        }
    }

    mouseOut() {
        if (window.innerWidth > breakpoints.lg) {
            if (this.element.classList.contains(this.activeClass)) {
                saveArticleButtonControllerStatus.added(this)
            } else {
                saveArticleButtonControllerStatus.add(this)
            }
        }
    }

    toggle() {
        // TODO: Window should be moved to root of project with Window.d.ts
        // @ts-ignore
        if (window.authState.subscription.hasSaveArticle) {
            this.addOrDeleteReadingList()
        } else {
            this.popupController.open()
        }
    }
}

export default SaveArticleButtonController;

application.register("save-article-button", SaveArticleButtonController);

import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../../../../jp-assets/src/ts/stimulus";
import { deleteAllItemsFromReadingList } from "../../service";
import { IPopup } from '../../../../popup/popup_controller';

class DeletePopupController extends Controller {
    htmlElement: HTMLElement = this.element as HTMLElement
    static classes = ["active"]


    popupElement: HTMLElement;
    popupController: IPopup;

    errorMessageElement: HTMLElement;
    errorMessageController: IPopup;

    saveArticleDeleteElements: NodeListOf<HTMLElement>;

    connect(): void {
        this.getControllers().catch((error: Error) => {
            console.error(error)
        })

    }

    delete() {
        deleteAllItemsFromReadingList().then(() => {
            this.popupController.close();
            this.hideSaveArticleDeleteElements()
        }).catch((error) => {
            console.error(error)
            this.popupController.close();
            this.errorMessageController.open()
        })
    }

    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getControllers());
    }

    // Wait for all Stimulus controllers to load
    private getControllers(): Promise<any> {
        return Promise.all([
            this.getPopupController(),
            this.getErrorMessageController()
        ]).then((controllers: any[]) => {
            if (controllers.includes(null)) {
                return this.getControllerAgainAfterTimeout(200)
            } else {
                return controllers
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getPopupController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.popupElement = document.querySelector("[data-popup-name='delete']")
            if (this.popupElement) {
                this.popupController = this.application.getControllerForElementAndIdentifier(
                    this.popupElement,
                    "popup")
                resolve(this.popupController)

            } else {
                reject(new Error("No popup controller found"))
            }
        })
    }

    // wait for all Stimulus controllers to load
    private getErrorMessageController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.errorMessageElement = document.querySelector("[data-popup-name='error-popup']")
            if (this.errorMessageElement) {
                this.errorMessageController = this.application.getControllerForElementAndIdentifier(
                    this.errorMessageElement,
                    "popup")
                resolve(this.errorMessageController)
            } else {
                reject(new Error("No popup controller found"))
            }
        })
    }

    private hideSaveArticleDeleteElements() {
        return new Promise((resolve, reject) => {
            this.saveArticleDeleteElements = document.querySelectorAll("[data-controller='save-article-delete']")
            this.saveArticleDeleteElements.forEach((element: HTMLElement) => {
                this.htmlElement.style.display = "none"
            })
        })
    }
}

export default DeletePopupController;

application.register("delete-popup", DeletePopupController);

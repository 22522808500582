import { ISaveArticleButtonController } from "../message/save_article_button_controller";

interface ISaveArticleButtonControllerStatus {
    add: (element: ISaveArticleButtonController) => void
    added: (element: ISaveArticleButtonController) => void
    remove: (element: ISaveArticleButtonController) => void
}

class SaveArticleButtonControllerStatus implements ISaveArticleButtonControllerStatus {
    add(element: ISaveArticleButtonController) {
        element.textTarget.textContent = element.textAdd
        element.iconSaveTarget.hidden = false
        element.iconSavedTarget.hidden = true
        element.iconCloseTarget.hidden = true
    }

    added(element: ISaveArticleButtonController) {
        element.textTarget.textContent = element.textAdded;
        element.iconSaveTarget.hidden = true
        element.iconSavedTarget.hidden = false
        element.iconCloseTarget.hidden = true
    }

    remove(element: ISaveArticleButtonController) {
        element.textTarget.textContent = element.textRemove
        element.iconSaveTarget.hidden = true
        element.iconSavedTarget.hidden = true
        element.iconCloseTarget.hidden = false
    }
}

const saveArticleButtonControllerStatus = new SaveArticleButtonControllerStatus()

export default saveArticleButtonControllerStatus

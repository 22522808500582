import { Controller } from "../../../../jp-assets/node_modules/stimulus";
import { application } from "../../../../jp-assets/src/ts/stimulus";

export default class BarchartController extends Controller {
    connect(): void {
        this.updateBarchart(this.element.id);
        this.updateNotes(this.element.id);
    }

    updateBarchart(id) {
        let barchartElement = this.element as HTMLElement;

        // retrieve/extract values and calculate length of array
        let parsedInput = JSON.parse(barchartElement.dataset.values);
        let inputValues = Object.keys(parsedInput).map(function (k) {
            return parsedInput[k].value;
        }); // get values from object
        let numberValues = inputValues.length;

        // get label (unit)
        let unit = barchartElement.dataset.unit;

        //loop through values and adding valid values to object and to total

        let valuesObjectItem = {};
        let valuesObject = [];
        let valuesTotal = 0;

        let partiesObject = [];

        for (let i = 0; i < numberValues; i++) {
            let id = i + 1; // id starting at 1, not 0

            let value =
                typeof inputValues[i] == "number"
                    ? inputValues[i]
                    : +inputValues[i].replace(",", "."); //if string - convert value to number and replace comma

            if (value >= 0) {
                //Validation: must be at least 0, otherwise skip

                // Get parties for party letter blocks
                partiesObject.push(parsedInput[i].parties);

                valuesObjectItem = {
                    value: value,
                    id: id,
                    color: "var(--color" + id + ",var(--fallbackColor))",
                };

                // add data to object
                valuesObject.push(valuesObjectItem);

                //add value to total
                valuesTotal += value;
            }
        }

        //add elements based on valuesObject and set width/label

        // get element containing bar chart
        let barchartContainer = barchartElement.querySelector(
            ".c-jp-barchart__container"
        );

        //variable used to transform elements (move them)
        var startingPosition = 0;

        try {
            for (let i = 0; i < valuesObject.length; i++) {
                // creating bar section, adding id, setting color variable
                let barSlice = document.createElement("div");
                barSlice.classList.add(
                    "c-jp-barchart__bar-slice",
                    "m--option-" + valuesObject[i].id
                );
                barSlice.style.backgroundColor = valuesObject[i].color;

                //adding label and inserting values (+unit)
                let barSliceLabel = document.createElement("span");
                barSliceLabel.classList.add("c-jp-barchart__bar-slice__label");
                barSliceLabel.innerText =
                    valuesObject[i].value.toLocaleString("da-DK") + unit;
                barSliceLabel.style.color = valuesObject[i].color;

                let partyLetterWrapper = document.createElement("div");
                partyLetterWrapper.classList.add(
                    "c-jp-barchart__bar-slice__party-letter-wrapper"
                );

                //right-align second label if only two values/categories
                if (numberValues == 2 && i == 1) {
                    barSlice.style.textAlign = "right";
                }

                //calculating and setting width
                let widthPct = (valuesObject[i].value / valuesTotal) * 100;
                barSlice.style.width = "calc(" + widthPct + "% + 1px)";

                //move elements (move horizontally)
                barSlice.style.left = startingPosition + "%";
                startingPosition += widthPct;

                for (let p of partiesObject[i]) {
                    let partyLetter = document.createElement("span");
                    partyLetter.classList.add("party-letter", "m--" + p);
                    partyLetterWrapper.appendChild(partyLetter);
                }

                // add slice and label to container/DOM
                barSlice.appendChild(partyLetterWrapper);
                barSlice.appendChild(barSliceLabel);
                barchartContainer.appendChild(barSlice);
            }
        } catch (e) {
            console.warn(
                "The barchart was removed due to one or more parameters missing... The error was:",
                e
            );
            barchartElement.remove();
        }

        //add center marker if data-centermarker is set to true

        if (barchartElement.dataset.centermarker == "true") {
            let centerMarker = document.createElement("div");
            centerMarker.classList.add("c-jp-barchart__center-marker");
            barchartContainer.appendChild(centerMarker);
        }
    }

    updateNotes(id) {
        let barchartElement = this.element as HTMLElement;

        // get values from dom-element
        let source = barchartElement.dataset.source;
        let updated = barchartElement.dataset.updated;
        let counted = barchartElement.dataset.counted;
        let additionalDisclaimer = barchartElement.dataset.additionaldisclaimer;

        //defining prefixes
        let prefix = {
            source: "Kilde",
            updated: "Opdateret",
        };

        // add prefixes
        let sourceString = source ? `${prefix.source}: ${source}` : "";
        let updatedString = updated ? `${prefix.updated} ${updated}` : "";

        // add suffix
        let countedString = counted ? `${counted} % optalt` : "";

        //Genererate notes string
        let notesString =
            source && updated
                ? "".concat(updatedString, " | ", sourceString)
                : "".concat(updatedString, sourceString); // add separator if both source and updated are true

        //View all results link
        let resultlinkElement = document.createElement("a");
        resultlinkElement.href = barchartElement.dataset.resultlink;
        resultlinkElement.classList.add("c-jp-barchart__results-link");
        resultlinkElement.innerText = "Se hele resultatet";

        additionalDisclaimer
            ? (notesString = additionalDisclaimer + " | " + notesString)
            : notesString;

        let notesContainer = document.createElement("div");
        notesContainer.classList.add("c-jp-barchart__notes-container");

        // generate counted string
        let countedElement = document.createElement("div");
        countedElement.classList.add("c-jp-barchart__notes");
        countedElement.innerHTML = countedString;

        //create note element, add class and text
        let noteElement = document.createElement("div");
        noteElement.classList.add("c-jp-barchart__notes");
        noteElement.innerText = notesString;

        barchartElement.prepend(resultlinkElement);
        notesContainer.append(noteElement);
        notesContainer.append(countedElement);
        barchartElement.appendChild(notesContainer);
    }
}

application.register("barchart", BarchartController);

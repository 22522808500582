import {Controller} from "../../../../../jp-assets/node_modules/stimulus"
import {application} from "../../../../../jp-assets/src/ts/stimulus";

class FactBoxController extends Controller {
    factBoxTarget: HTMLElement
    containerTarget: HTMLElement
    overlayTarget: HTMLElement
    btnTarget: HTMLElement
    btnDivTarget: HTMLElement
    activeClass: string;
    staticClass: string;

    static targets = ["container", "overlay", "btn", "btnDiv", "factBox"]
    static classes = ["active", "static"]


    connect() {
        if (this.containerTarget.scrollHeight <= 110) {
            this.element.classList.add(this.staticClass);
        }
    }

    toggle() {
        this.element.classList.toggle(this.activeClass)
        this.containerTarget.style.maxHeight = `${this.containerTarget.scrollHeight}px`;
        this.containerTarget.classList.remove("tw-max-h-[60px]")
        this.overlayTarget.remove();
        this.btnTarget.remove();
        this.btnDivTarget.remove();
    }
}

export default FactBoxController;

application.register("fact-box", FactBoxController);

import { Controller } from "../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../jp-assets/src/ts/stimulus";

enum OverlayType {
    Byline = 'byline',
    Share = 'share',
    Centered = 'centered'
}

export default class ToggleOverlayController extends Controller {
    private lockPageYOffset = 0;

    connect() {
        document.onkeydown = e => {
            if (e.key === "Escape") {
                this.hideOverlay();
            }
        };

        window.addEventListener('orientationchange', () => {
            this.hideOverlay();
        })

        document.addEventListener(
            "click", e => {
                if (!(e.target as HTMLElement).matches('[data-action^="click->toggle-overlay"]') &&
                    !(e.target as HTMLElement).closest('[data-action^="click->toggle-overlay"]') &&
                    !(e.target as HTMLElement).closest('.c-overlay')) {
                    this.hideOverlay();
                }
            },
            false
        )
    }

    showOverlay(event: any) {
        // Hide any already-open overlays.
        this.hideOverlay();

        this.lockScroll(true);

        const buttonElement = event.target.closest('[data-overlay-id]') || event.target;

        const overlayId = buttonElement.getAttribute('data-overlay-id');

        const overlayElement = document.getElementById(overlayId)!;
        const overlayType = buttonElement.getAttribute('data-overlay-type');

        overlayElement.classList.add('c-overlay--type-' + overlayType, 'c-overlay--show');

        this.positionOverlay(buttonElement, overlayElement, overlayType);


    }

    hideOverlay() {
        const overlays: any = document.getElementsByClassName("c-overlay--show");
        // Because hideOverlay() is called in showOverlay() there might not be an overlay to hide. In that case, we
        // should not perform e.g. lockScroll() as that will scroll the window to the top of the page.
        if (overlays.length == 0) return;

        for (let item of overlays) {
            item.classList.remove('c-overlay--show');
            this.resetOverlayPosition(item);
        }
        this.lockScroll(false);
        document.body.classList.remove('m--showing-overlay');
    }

    private resetOverlayPosition(overlayElement: HTMLElement) {
        overlayElement.style.top = '';
        overlayElement.style.left = '';
        this.showTopscrollBanners();
    }

    private positionOverlay(buttonElement: HTMLElement, overlayElement: HTMLElement, overlayType: string) {
        if (window.innerWidth >= 1024 && (overlayType === OverlayType.Byline || overlayType === OverlayType.Share)) {
            const authorElementOffset = this.getOffset(buttonElement.closest('.c-article-top-byline__author') || buttonElement);
            const mainWrapperOffset =  this.getOffset(document.getElementsByTagName('body')[0]);
            overlayElement.style.top = authorElementOffset.top - mainWrapperOffset.top + 'px';
            overlayElement.style.left = authorElementOffset.left - mainWrapperOffset.left + 'px';
        } else if(window.innerWidth < 1024){
            // Removing topscroll banners for proper positioning on mobile
            this.hideTopscrollBanners();
        }
    }
    private showTopscrollBanners() {
        document.querySelector('[class*="topscroll"]') && document.querySelector('[class*="topscroll"]')!.classList.remove('d-none');
    }
    private hideTopscrollBanners() {
        document.querySelector('[class*="topscroll"]') && document.querySelector('[class*="topscroll"]')!.classList.add('d-none');
    }
    private getOffset(el: Element) {
        const rect = el.getBoundingClientRect();
        return {
            left: rect.left,
            top: rect.top,
        };
    }

    private lockScroll(lock: boolean) {
        if (lock) {
            if (window.innerWidth < 1024) {
                this.lockPageYOffset = pageYOffset;
                document.body.style.position = 'fixed';
                document.body.style.width = '100%';
            }
        } else {
            document.body.style.position = '';
            document.body.style.width = '';
            if (window.innerWidth < 1024) {
                window.scrollTo(0, this.lockPageYOffset || 0);
            }
        }
    }
}

application.register("toggle-overlay", ToggleOverlayController);

import { CookieService } from '../../../../../jp-assets/src/ts/services/CookieService';
const buildRequest = (url: string) => {
    return fetch(url, {
        method: "GET",
    })
        .then((response) => response.json()
            .then(data => data))
        .catch((error) => {
            console.error(
                "Unable to perform fetch operation: " + error.message
            );
        });
};

export function getStatistics() {
    return buildRequest("/give-article/check-statistics");
}
export function validateToken(article: number, token: string) {
    return buildRequest(`/give-article/validate-token/${article}/${token}`);
}

export function getShareToken(article: number) {
    return buildRequest(`/give-article/generate-token/${article}`);
}

export class Deferred {
    public promise: Promise<void>;
    public resolve: () => void = () => undefined;
    public reject: () => void = () => undefined;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}


const csrfToken = CookieService.getCookie('csrf_token');

const performRequest = (url: string) => {
    // Check if response is 200 and handle errors
    // in the Stimulus controllers
    return fetch(url, {
        method: 'POST',
        headers: {
            'Csrf-Token': csrfToken
        }
    })
        .then(response => {
            switch (response.status) {
                case 200:
                    break;
                default:
                    throw Error(response.statusText);
            }
        })
}

export function getReadingList(): Promise<any> {
    // Handle errors in the Stimulus controllers
    return fetch('/read-list/get-all', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    })
        .then(response => {
            switch (response.status) {
                case 200:
                    return response.json();
                default:
                    throw Error(response.statusText);
            }
        }).catch(error => {
            console.error(error);
        })
}

export function getMoreReadingListItems(offset: number): Promise<any> {
    // Handle errors in the Stimulus controllers
    return fetch(`/read-list/get-more-items/${offset}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/html',
            'Accept': 'text/html'
        }
    })
        .then(response => {
            switch (response.status) {
                case 200:
                    return response;
                default:
                    throw Error(response.statusText);
            }
        })
}

export function addItemToReadingList(item: string) {
    return performRequest(`/read-list/add-item/${item}`)
}

export function deleteItemFromReadingList(item: string) {
    return performRequest(`/read-list/delete-item/${item}`)
}

export function deleteAllItemsFromReadingList() {
    return performRequest('/read-list/delete-all')
}

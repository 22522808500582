import {Controller} from "../../../../jp-assets/node_modules/stimulus";
import {application} from "../../../../jp-assets/src/ts/stimulus";
import {IPopup} from "../popup/popup_controller";

export default class NewsletterboxController extends Controller {
    popupController: IPopup;
    popupElement: HTMLElement;

    static targets = ["tooltip", "button", "email", "checkbox"]

    declare readonly tooltipTarget: HTMLElement;
    declare readonly buttonTarget: HTMLInputElement;
    declare readonly checkboxTargets: Array<HTMLInputElement>;


    connect() {
        this.getControllers()
            .catch((error: Error) => {
                console.error(error)
            })
    }

    initialize() {
        this.buttonTarget.addEventListener('click', (event: any) => {
            event.preventDefault()
            this.subscribe()
        })

        let emailElement = document.querySelectorAll("#newsletters__email-field")[0] as HTMLInputElement;
        emailElement.addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                this.subscribe()
            }
        })

        let form = document.getElementById("c-zone-commerce-ea__form")
        if(form) {
        form.addEventListener("submit", (event: any) => {
                event.preventDefault()
            })}
    }

    private subscribe() {
        const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // from https://emailregex.com/
        let list = this.buttonTarget.dataset.mailinglist;
        let message = document.querySelectorAll(".newsletters__email-validation-msg")[0]
        let listNb = this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.dataset.mailinglist).join(",")
        let emailElement = document.querySelectorAll("#newsletters__email-field")[0] as HTMLInputElement;
        let checkbox = document.getElementsByClassName("newsletters__checkbox-input")[0];
        let email = emailElement.value
        let emailOffset = emailElement.offsetTop
        if (this.buttonTarget.value == "Kræver abonnement" ||
            this.buttonTarget.value == "Vil du vide mere?") {
            this.popupController.open()
        } else if (!emailPattern.test(email)) {
            if (!emailElement.classList.contains("c-zone-commerce-ea__input")) {
                this.invalidMail(this.buttonTarget, message)
                this.scrollToField(emailOffset)
            } else {
                this.invalidMailErhvervsadgang(this.buttonTarget, message)
            }
        } else {
            // js y u no encodeURI('+') == '%2b' js u drunk go home
            const encodedEmail = email
                .split('+')
                .map(fragment => encodeURI(fragment))
                .join('%2b');

            if (this.buttonTarget.value == "Tilmeld") {
                const listParam = this.buttonTarget.classList.contains("jp-btn")
                    ? list
                    : listNb;

                fetch('/nyhedsbrev/subscribe?email=' + encodedEmail + '&list=' + listParam).then((r) => {
                    switch (r.status) {
                        case 201:
                            if (!emailElement.classList.contains("c-zone-commerce-ea__input")) {
                                this.successButton(this.buttonTarget, message)
                                this.subscribedToolTip()
                            } else {
                                this.successButtonErhvervsadgang(message, emailElement, checkbox)
                            }
                            break;
                        case 200:
                            if (!emailElement.classList.contains("c-zone-commerce-ea__input")) {
                                this.alreadyCreatedMessage(message)
                                this.failureButton(this.buttonTarget)
                                this.scrollToField(emailOffset)
                            } else {
                                this.alreadyCreatedMessageErhvervsadgang(message, emailElement, checkbox)
                            }
                            break;
                        default:
                            if (!emailElement.classList.contains("c-zone-commerce-ea__input")) {
                                this.failureMessage(message)
                                this.failureButton(this.buttonTarget)
                                this.scrollToField(emailOffset)
                            } else {
                                this.failureMessageErhvervsadgang(message, listParam)
                            }

                    }
                    return r
                })
            } else if (this.buttonTarget.value == "Afmeld") {
                fetch('/nyhedsbrev/unsubscribe?email=' + encodedEmail + '&list=' + list)
                    .then((r) => {
                        switch (r.status) {
                            case 200:
                                this.successUnSubscribeButton(this.buttonTarget, message)
                                this.unSubscribedToolTip()
                                break;
                            default:
                                this.failureMessage(message)
                                this.failureButton(this.buttonTarget)
                                this.scrollToField(emailOffset)

                        }
                        return r
                    })
            }
        }
    }

    private subscribedToolTip() {
        this.tooltipTarget.classList.remove("m--hidden")
    }

    private unSubscribedToolTip() {
        this.tooltipTarget.classList.add("m--hidden")
    }

    private scrollToField(offset: number) {
        window.setTimeout(() => {
            window.scrollTo({top: offset - 100, left: 0, behavior: 'smooth'})
        }, 300)
    }

    private successButton(button: HTMLInputElement, message: any) {
        button.value = "Afmeld"
        button.classList.add("m--unsubscribe")
        message.classList.add("m--hidden")
    }

    private successUnSubscribeButton(button: HTMLInputElement, message: any) {
        button.value = "Tilmeld"
        button.classList.remove("m--unsubscribe")
        message.classList.add("m--hidden")
    }

    private alreadyCreatedMessage(message: any) {
        message.classList.remove('m--hidden')
        message.classList.add('m--invalid');
        message.innerHTML = "Du er allerede tilmeldt nyhedsbrevet."
    }

    private failureButton(button: HTMLElement) {
        button.classList.add('m--invalid')
        window.setTimeout(() => {
            button.classList.remove('m--invalid')
        }, 500)
    }

    private failureMessage(message: any) {
        message.classList.remove('m--hidden')
        message.classList.add('m--invalid');
        message.innerHTML = "Der er desværre sket en fejl. Prøv venligst igen."
    }

    private invalidMail(button: HTMLInputElement, message: Element) {
        message.classList.remove('m--hidden')
        message.classList.add('m--invalid');
        message.innerHTML = "Indtast venligst en gyldig e-mail"
        this.failureButton(button)
    }

    //--- Erhversadgang ----

    private successButtonErhvervsadgang(message: Element, input: HTMLInputElement, checkbox: any) {
        message.innerHTML = "Tak for din tilmelding."
        message.classList.add("email-feedback")
        input.value = "";
        checkbox.checked = false;

        setTimeout(function () {
            message.classList.remove("email-feedback")
        }, 2000)
    }

    private invalidMailErhvervsadgang(button: HTMLInputElement, message: Element) {
        this.failureButton(button)
        message.classList.add("email-feedback")
        message.innerHTML = "Indtast venligst en gyldig e-mail"

        setTimeout(function () {
            message.innerHTML = ""
            message.classList.remove("email-feedback")
        }, 2000)
    }

    private alreadyCreatedMessageErhvervsadgang(message: Element, input: HTMLInputElement, checkbox: any) {
        message.classList.add("email-feedback")
        message.innerHTML = "Du er allerede tilmeldt nyhedsbrevet."
        input.value = "";
        checkbox.checked = false;

        setTimeout(function () {
            message.innerHTML = ""
            message.classList.remove("email-feedback")
        }, 2000)
    }

    private failureMessageErhvervsadgang(message: Element, list: String) {
        message.classList.add("email-feedback")
        if(list === "") {
            message.innerHTML = "Vælg venligst minimum 1 nyhedsbrev."
        } else {
            message.innerHTML = "Der er desværre sket en fejl. Prøv venligst igen."
        }
        setTimeout(function () {
            message.innerHTML = ""
            message.classList.remove("email-feedback")
        }, 2000)
    }

    //--------//

    private getPopupController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.popupElement = document.querySelector("[data-popup-name='newsletter']")
            if (this.popupElement) {
                this.popupController = this.application.getControllerForElementAndIdentifier(
                    this.popupElement,
                    "popup")
                resolve(this.popupController)
            } else {
                console.log("Could not find popup element")
            }
        })
    }

    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getControllers());
    }

    private getControllers(): Promise<any> {
        return Promise.all([
            this.getPopupController(),
        ]).then((controllers: any[]) => {
            if (controllers.includes(null)) {
                return this.getControllerAgainAfterTimeout(200)
            } else {
                return controllers
            }
        })
    }
}


application.register("newsletterbox", NewsletterboxController);

const elements = document.querySelectorAll(".medieloginIsDown")

for (let i = 0; i < elements.length; i++) {
    const element = elements[i] as HTMLButtonElement
    elements[i].addEventListener('click', function (e) {
        element.removeAttribute('data-js-sel')
        const elementLogin = document.querySelector("[data-popup-name='medielogin-login-popup']")
        const elementLogout = document.querySelector("[data-popup-name='medielogin-logout-popup']")
        document.documentElement.style.overflow = "hidden"
        if(elements[i].classList.contains('jp-header__btn-login')) {
            elementLogin.classList.add('c-popup--open')
        } else {
            elementLogout.classList.add('c-popup--open')
        }
    })
}

if(document.querySelector(".medieloginIsDown")) {
    addEventListener('DOMContentLoaded', (event) => {
    const elements = document.querySelectorAll("[href='javascript:login_badge_login()']")
    for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLButtonElement
        elements[i].addEventListener('click', function (e) {
            element.removeAttribute("href")
            const elementLogin = document.querySelector("[data-popup-name='medielogin-login-popup']")
            elementLogin.classList.add('c-popup--open')
        })
    }})
}

import { Controller } from "stimulus"
import { application } from "../../stimulus";

let siteHeader: HTMLElement;
const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

class BannerTopbarController extends Controller {
    initialize() {
        siteHeader = (document as Document).getElementById('site-header')!;
    }

    scrollDown() {
        const headerY = siteHeader.getBoundingClientRect().top + document.body.scrollTop;
        if (supportsNativeSmoothScroll) {
            (window as Window).scrollTo({
                top: headerY,
                behavior: "smooth"
            });
        } else {
            (window as Window).scrollTo(0, headerY);
        }
    }
}

export default BannerTopbarController;

application.register("banner-topbar", BannerTopbarController);

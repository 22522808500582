import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../../../../jp-assets/src/ts/stimulus";
import { deleteItemFromReadingList } from "../../service";

export default class SaveArticleDeleteController extends Controller {
    htmlElement: HTMLElement = this.element as HTMLElement
    static targets = ["undo", "sliderIn"]
    static classes = ["sliderIn", "sliderOut", "sliderDelete"]

    static values = {
        id: String
    }

    idValue: string

    declare undoTarget: HTMLElement;
    declare sliderInTarget: HTMLElement;

    declare sliderInClass: string;
    declare sliderOutClass: string;
    declare sliderDeleteClass: string;

    private state: "idle" | "deleted";

    connect() {
        this.resizeArticle();
    }

    delete() {
        this.state = "deleted";
        this.sliderInTarget.classList.add(this.sliderInClass);
        this.sliderInTarget.classList.remove(this.sliderOutClass);
        this.deleteArticle();
    }

    undo() {
        this.state = "idle";
        this.sliderInTarget.classList.add(this.sliderOutClass);
        this.sliderInTarget.classList.remove(this.sliderInClass);
    }

    private deleteArticle() {
        this.undoTarget.addEventListener("click", () => {
            this.undo();
        })

        // The user gets 4 seconds to decide whether to delete the article or not
        setTimeout(() => {
            if (this.state === "deleted") {
                this.sliderInTarget.classList.add(this.sliderDeleteClass);

                this.htmlElement.style.transition = "height 300ms";
                this.htmlElement.style.height = "0px";
                this.htmlElement.style.visibility = "hidden";

                // Hide article after the animation is finished
                setTimeout(() => {
                    deleteItemFromReadingList(this.idValue).then(() => {
                        this.htmlElement.style.display = "none";
                    }).catch(() => {
                        this.sliderInTarget.classList.remove(this.sliderDeleteClass);
                        this.undo();
                        console.error("Error deleting article");
                    });
                }, 500);
            }
        }, 4000);
    }

    private resizeArticle() {
        this.htmlElement.style.height = this.htmlElement.offsetHeight + "px";

        window.addEventListener("resize", () => {
            this.htmlElement.style.height = "";
            this.htmlElement.style.height = this.htmlElement.offsetHeight + "px";
        });
    }
}


application.register("save-article-delete", SaveArticleDeleteController);

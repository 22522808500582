import {Controller} from "../../../../../jp-assets/node_modules/stimulus";
import {application} from "../../../../../jp-assets/src/ts/stimulus";

export default class Checkbox extends Controller{
    Element: HTMLElement

    static targets = ["dots", "more", "button"]

    declare readonly dotsTarget: HTMLElement
    declare readonly moreTarget: HTMLElement
    declare readonly buttonTarget: HTMLElement


    read() {
        if(this.dotsTarget.style.display === "none") {
            this.dotsTarget.style.display = "inline";
            this.buttonTarget.innerHTML = "Læs mere her"
            this.moreTarget.style.display = "none"
        } else {
            this.dotsTarget.style.display = "none";
            this.buttonTarget.innerHTML = "Læs mindre";
            this.moreTarget.style.display = "inline";
        }
    }

}


application.register("checkbox", Checkbox)

import { application } from "../../../../jp-assets/src/ts/stimulus";
import { Controller } from "../../../../jp-assets/node_modules/stimulus";
import { IPopup } from '../popup/popup_controller';
import RetryUntilDefined from "../../../../jp-assets/src/ts/util/RetryUntilDefined";
import {CookieService} from "../../../../jp-assets/src/ts/services/CookieService";

class GetPopupResult {
    popupController: IPopup;
    popupElement: HTMLElement;

    constructor(popupController: IPopup, popupElement: HTMLElement){
        this.popupController = popupController;
        this.popupElement = popupElement;
    }
}

const csrfToken = CookieService.getCookie('csrf_token');

class MyTopicsController extends Controller {
    static values = {
        tagid: String,
        isfollowing: Boolean,
        hasaccess: Boolean,
        site: String,
    };
    tagidValue: string;
    hasaccessValue: boolean;
    isfollowingValue: boolean;
    followUnfollowMethod: string;
    siteValue: string;

    jpStylesNotFollowing = "background: white; color: #35B6AD";
    jpStylesFollowing = "background: #35B6AD; color: white";
    finansStylesNotFollowing = "background: white; color: #a70a2c";
    finansStylesFollowing = "background: #a70a2c; color: white";

    static targets = ["followUnfollowButton"];
    followUnfollowButtonTarget: HTMLElement;
    myTopicsUrl: string;

    popupControllerReadyPromise: Promise<GetPopupResult> = RetryUntilDefined.asPromise(10000, () => this.getPopup(this))

    setButtonStyles(isfollowing, site) {
        if (isfollowing == true) {
            site == "Jyllands-Posten" ? this.followUnfollowButtonTarget.setAttribute("style", this.jpStylesFollowing) : this.followUnfollowButtonTarget.setAttribute("style", this.finansStylesFollowing);
        } else {
            site == "Jyllands-Posten" ? this.followUnfollowButtonTarget.setAttribute("style", this.jpStylesNotFollowing) : this.followUnfollowButtonTarget.setAttribute("style", this.finansStylesNotFollowing);
        }
    }

    private getPopup(controller: MyTopicsController): GetPopupResult {
        let popupElement = document.querySelector("[data-popup-name='my-topics-unavailable']") as HTMLElement;

        const popupController = controller.application.getControllerForElementAndIdentifier(popupElement, "popup");

        if(popupController){
            return new GetPopupResult(popupController, popupElement);
        } else {
            return undefined;
        }
    }

    connect() {
        this.setButtonStyles(this.isfollowingValue, this.siteValue);
        if (this.hasaccessValue == true) {
            this.myTopicsUrl = `${window.location.origin}/api/v1/my-topics/${this.tagidValue}`;
            if (this.isfollowingValue == true) {
                this.followUnfollowMethod = "DELETE";
            } else {
                this.followUnfollowMethod = "POST";
            }
        }
        this.element.classList.remove("d-none"); // Remove d-none class to show button. This is to avoid a flash of the button before the controller is connected.
    }

    toggle() {
        if (this.isfollowingValue == true) {
            this.isfollowingValue = false;
            this.followUnfollowMethod = "POST";
            this.followUnfollowButtonTarget.innerHTML = "Følg";
        } else {
            this.isfollowingValue = true;
            this.followUnfollowMethod = "DELETE";
            this.followUnfollowButtonTarget.innerHTML = "Følger";
        }
        this.setButtonStyles(this.isfollowingValue, this.siteValue);
    }

    followUnfollow() {
        // Save the method before toggling
        const method = this.followUnfollowMethod
        if (this.myTopicsUrl !== null && this.hasaccessValue == true) {
            this.toggle();
            fetch(this.myTopicsUrl, {
                method: method,
                headers: {
                    "Content-Type": "text/plain",
                    "Csrf-Token": csrfToken
                },
            })
                .then((response) => response.text())
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            this.openPopup();
        }
    }
    openPopup() {
        this.popupControllerReadyPromise.then(result => {
            result.popupController.open()
        })
    }
}

export default MyTopicsController;

application.register("my-topics", MyTopicsController);

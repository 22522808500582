export class CMPService {

    public static awaitOtherCookiesConsentPromise: Promise<any>;


    public static checkOtherCookiesConsent(): Promise<any> {
        if (!CMPService.awaitOtherCookiesConsentPromise) {
            CMPService.awaitOtherCookiesConsentPromise = CMPService.getOtherCookiesPromise();
        }

        return CMPService.awaitOtherCookiesConsentPromise;
    }

    private static getOtherCookiesPromise(): Promise<void> {
        return new Promise((resolve, reject) => {
            const consentDataReady = (window['consentDataReadyPromise']);
            if(consentDataReady) {
                consentDataReady.then(() => {
                    const hasConsent = window.cmpHandler.hasConsent("AppNexus", "marketing")
                    if (hasConsent) {
                        resolve()
                    } else {
                        reject()
                    }
                });
            }
        });
    }
}

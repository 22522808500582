import FetchWithTimeoutClient from "../clients/FetchWithTimeoutClient";
import ScriptLoaderService from "./ScriptLoaderService";
import MainControllerCoordinationService from "./MainControllerCoordinationService";
import GamBannerService from '../banners/services/GamBannerService';
import {CMPService} from "./CMPService";

import {Banner} from "../banners/types/Banner.model";
import {BannerHelper} from "../banners/helpers/BannerHelper";

export interface IEditorialFrontendService {
  connect(): void
}

export class EditorialFrontendService implements IEditorialFrontendService {

  private static readonly userreportScriptUrl: URL = new URL("https://sak.userreport.com/jppol/launcher.js");
  private readonly scriptLoader: ScriptLoaderService;
  private gamBannerService: GamBannerService;
  private readonly anonId: string;
  private readonly relevanceSegments: string[];

  constructor(anonId: string, relevanceSegments: string[]) {
    const fetcher = new FetchWithTimeoutClient();
    this.scriptLoader = new ScriptLoaderService(fetcher);

    this.anonId = anonId;
    this.relevanceSegments = relevanceSegments;
    EditorialFrontendService.setAudienceProjectLayer(anonId);
  }

  connect() {
    this
      .initializeFrontpage()
      .then((_: any) => {
        MainControllerCoordinationService.mainControllerReady();
      })
      .catch(error => {
        console.error(`An unexpected error happened: ${error.toString()}`)
        MainControllerCoordinationService.mainControllerFailed();
      })
  }

  private static setAudienceProjectLayer(anonId: string): void {
    // We use our own anonId as the JPPOLid.
    const args = ['sync', 'jppol', 'jpid', anonId];
    window.audienceProjectLayer = (window.audienceProjectLayer || []).concat(args);
  }

  private static setSegments(segments: string[]): void {
    window.relevance_segments = segments || [];
  }

  protected initializeFrontpage(): Promise<void> {
    EditorialFrontendService.setSegments(this.relevanceSegments);
    this.gamBannerService = new GamBannerService();
    this.gamBannerService.initialize();
    return this.scriptLoader.injectScript(EditorialFrontendService.userreportScriptUrl, "userreport_script_container");
  }
}

import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../../../../jp-assets/src/ts/stimulus";
import { IPopup } from "../../../../popup/popup_controller";

export default class SaveArticleTemplateController extends Controller {

    popupElement: HTMLElement;
    popupController: IPopup;
    errorMessageElement: HTMLElement;
    errorMessageController: IPopup;

    connect() {

        this.getPopupController().catch(() => {
            this.getControllerAgainAfterTimeout(200)
        })
    }

    toggle() {
        this.popupController.open();
    }

    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getPopupController());
    }


    // wait for all Stimulus controllers to load
    private getPopupController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.popupElement = document.querySelector("[data-popup-name='delete']")
            this.popupController = this.application.getControllerForElementAndIdentifier(
                this.popupElement,
                "popup")

            if (this.popupController) {
                resolve(this.popupController)
            } else {
                reject(console.error("No popup controller found"))
            }
        })
    }
}


application.register("save-article-template", SaveArticleTemplateController);

import {Controller} from "../../../../../jp-assets/node_modules/stimulus";
import {application} from "../../../../../jp-assets/src/ts/stimulus";

class ShareArticlePopupController extends Controller {

    static targets: string [] = [
        "share-messenger"
    ]

    shouldShowMessengerButton() {
        let userAgent: string = navigator.userAgent || navigator.vendor;
        if (
            /android/i.test(userAgent) ||
            (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
        ) {
            this.element
                .querySelector("[data-button-type='share-messenger']")
                .classList.remove("d-none");
        }
    }

    connect() {
        this.shouldShowMessengerButton()
    }
}

export default ShareArticlePopupController

application.register("share-article-popup", ShareArticlePopupController)

import { Controller, ControllerConstructor } from "../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../jp-assets/src/ts/stimulus";

export interface IPopup extends Controller {
    open?: () => void
    close?: () => void
}

class PopupController extends Controller implements IPopup {
    activeClass: string;
    blurClass: string;

    static classes = ["active", "blur"]


    open() {
        this.element.classList.add(this.activeClass)
        document.documentElement.style.overflow = "hidden"
        window.dispatchEvent(new CustomEvent("popup-open", { detail: this.element.getAttribute("data-popup-name") }));
    }

    close() {
        this.element.classList.remove(this.activeClass)
        document.documentElement.style.overflow = "auto"
        window.dispatchEvent(new CustomEvent("popup-close", { detail: this.element.getAttribute("data-popup-name") }));
    }
}

export default PopupController;

application.register("popup", PopupController);

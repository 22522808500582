import { application } from "../../../../../../jp-assets/src/ts/stimulus";
import { Controller } from "../../../../../../jp-assets/node_modules/stimulus";
import { IPopup } from '../../../popup/popup_controller';
import RetryUntilDefined from "../../../../../../jp-assets/src/ts/util/RetryUntilDefined";

class GetPopupInfo {
    popupController: IPopup;
    popupElement: HTMLElement;

    constructor(popupController: IPopup, popupElement: HTMLElement){
        this.popupController = popupController;
        this.popupElement = popupElement;
    }
}
class TopicInfoPopupController extends Controller {

    popupInfoControllerReadyPromise: Promise<GetPopupInfo> = RetryUntilDefined.asPromise(10000, () => this.getInfoPopup(this))

    private getInfoPopup(controller: TopicInfoPopupController): GetPopupInfo {
        let popupInfoElement = document.querySelector("[data-popup-name='topics-info']") as HTMLElement;
        const popupController = controller.application.getControllerForElementAndIdentifier(popupInfoElement, "popup");

        if(popupController){
            return new GetPopupInfo(popupController, popupInfoElement);
        } else {
            return undefined;
        }
    }
    openInfoPopup() {
        this.popupInfoControllerReadyPromise.then(result => {
            result.popupController.open()
        })
    }
}

export default TopicInfoPopupController;

application.register("topic-info-popup", TopicInfoPopupController);

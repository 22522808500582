import { Controller } from "../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../jp-assets/src/ts/stimulus";


export default class LatestNewsTickerController extends Controller {
    connect() {
        // Event listener added here, to ensure that all elements that can affect the size of the ticker (fonts etc.) have been loaded before the code is executed.
        window.addEventListener("load", (event) => {
            this.initAnimation();
        })
    }

    private initAnimation() {
        const animatedEl: HTMLElement = this.element.getElementsByClassName('latest-news-ticker__animation')[0] as HTMLElement;
        const viewportEl: HTMLElement = this.element.getElementsByClassName('latest-news-ticker__wrapper')[0] as HTMLElement;
        const infPlaceholder: HTMLElement = this.element.getElementsByClassName('latest-news-ticker__collection2')[0] as HTMLElement
        const articleElements: HTMLCollection = this.element.getElementsByClassName('latest-news-ticker__collection1')[0].children;
        const stylesEl: HTMLElement= this.prepareStylesElement('tickerAnimationStylesId');
        const animatedElWidth: number = animatedEl.getBoundingClientRect().width;

        this.setAnimationStyles(animatedElWidth, viewportEl.getBoundingClientRect().width, stylesEl);
        window.addEventListener("resize", this.throttle(() => {
            this.setAnimationStyles(animatedElWidth, viewportEl.getBoundingClientRect().width, stylesEl)
        }, 100))

        for(let i = 0; i < articleElements.length; i++) {
            infPlaceholder.appendChild(articleElements[i].cloneNode(true));
        }
    }

    private prepareStylesElement(id: string) {
        const style = document.createElement('style');
        style.setAttribute("id", id);
        document.head.appendChild(style);

        return document.getElementById(id)!;
    }

    private setAnimationStyles(animatedElWidth: number, viewportElWidth: number, stylesEl: HTMLElement) {
        const SPEED = 80; // pixels/second
        const DISTANCE = animatedElWidth + viewportElWidth;
        const STARTING_POINT = .67; // Value between 0 and 1 to decide how far in the viewport the ticker should start

        stylesEl.innerHTML = `@keyframes ticker {
          0% {
            left: 50%;
            visibility: visible;
          }
          100% {
            left: 0%;
          }
        }
        .latest-news-ticker__animation{
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-name: ticker;
            animation-duration: ${DISTANCE / SPEED}s;
            animation-delay: -${(viewportElWidth * STARTING_POINT) / SPEED}s;
        }
        .latest-news-ticker__animation:hover{
                animation-play-state: paused;
        }`;
    }

    private throttle(callback: any, limit: number) {
        let tick = false;
        return function () {
            if (!tick) {
                callback.call();
                tick = true;
                setTimeout(function () {
                    tick = false;
                }, limit);
            }
        }
    }
}

application.register("latestNewsTicker", LatestNewsTickerController);

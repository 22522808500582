import TimeoutPromise from "../util/TimeoutPromise";

export default interface FetchWithTimeoutClient {
  new(): FetchWithTimeoutClient

  get(url: URL, timeoutInMillis: number): Promise<Response>
}

export default class FetchWithTimeoutClientImpl implements FetchWithTimeoutClient {

  private readonly abortController: AbortController;

  /**
   * Creates an instance of FetchWithTimeoutClientImpl.
   * <br> This is a limited wrapper around fetch() which implements timeout.
   */
  public constructor() {
    this.abortController = new AbortController();
  }

  /**
   * Performs a HTTP GET request against given url with the given timeout.
   * <br> NOTE: The returned promise is rejected in case of timeout.
   * @param url - the url to call.
   * @param timeoutInMillis - the timeout in milliseconds.
   * @returns a promise with the requested content.
   */
  public get(url: URL, timeoutInMillis: number): Promise<Response> {

    const fetchOpts: RequestInit = {
      method: 'GET',
      /*
       The abort controller signal below is used to close the connection opened by fetch.
       As a result, fetch throws a DOMException with name == 'AbortError' which in turn causes the promise to reject.
       */
      signal: this.abortController.signal,
      mode: 'cors'
    };

    const abortAction: () => void = () => this.abortController.abort();
    const action = window.fetch(url.toString(), fetchOpts);

    return TimeoutPromise.start(action, abortAction, timeoutInMillis);
  }
}

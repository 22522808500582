export default class RetryUntilDefined {

  /**
   * Starts a timed promise that repeats the given function until the result of that function is no longer 'undefined'.
   * The promise is rejected if the timeout is reached.
   *
   * Useful if you want to ensure something has been initialized before using it.
   *
   * @param timeoutInMillis - the timeout limit in milliseconds.
   * @param fun - the function to run.
   * @returns a promise with the result of the function or a rejected promise in case of timeout.
   */
  public static asPromise<Type>(timeoutInMillis: number, fun: () => Type): Promise<Type> {
      return new Promise<Type>((resolve, reject) => {
          const waitBetweenRetries: number = 100 // in milliseconds
          const triesLeft: number = timeoutInMillis / waitBetweenRetries

          function runOrRetryIfUndefined(triesLeft: number) {
              const result: Type = fun()
              // Resolve the promise if the function returns an object that is not 'undefined'
              if (result) {
                  resolve(result);
              } else if (triesLeft <= 0) {
                  reject(new Error(`IsDefinedPromise timed out after ${timeoutInMillis} ms waiting for function to complete`));
              } else {
                  // Try again if the function returns 'undefined'
                  setTimeout(runOrRetryIfUndefined.bind(null, triesLeft - 1), waitBetweenRetries);
              }
          }

          runOrRetryIfUndefined(triesLeft)
      })
  }
}

/**
 * Functionality for raw banner data parsing.
 */
import {Banner, Banners} from '../types/Banner.model';
import LayoutHelper from '../../util/LayoutHelper';
import {BannerType} from '../types/BannerType';


export abstract class BannerHelper {
    static isUserOnMobile() {
        return LayoutHelper.isUserOnMobile(window.innerWidth);
    }

    /**
     * Parses the raw banner data into an array of Banner objects.
     * @param bannerData - The data to parse
     * @returns banners - an array with the parsed banners
     */

    static transformRawBanners(bannerData: Banners): Banner[] {
        const bannerObject: { [key: string]: Banner } =
            this.isUserOnMobile()
                ? bannerData.mobileBanners
                : bannerData.desktopBanners;

        return Object.values(bannerObject);
    }

    public static shouldBannerBeShown(width: number, height: number, bannerType: string | null | undefined): boolean {
        return !!bannerType &&
            (LayoutHelper.isUserOnMobile(width) && bannerType === BannerType.MOBILE) ||
            (!LayoutHelper.isUserOnMobile(width) && bannerType === BannerType.DESKTOP) ||
            (LayoutHelper.isUserOnDesktopXL(width) && bannerType === BannerType.DESKTOP_XL) ||
            (LayoutHelper.canShowJpFrontpageTopflowHalfPage(width) && bannerType === BannerType.JP_FRONTPAGE_TOPFLOW_HALF_PAGE) ||
            (LayoutHelper.canShowJpFrontpageFullGridHalfPage160(width) && bannerType === BannerType.JP_FRONTPAGE_FULL_GRID_HALF_PAGE_160) ||
            (LayoutHelper.canShowJpFrontpageFullGridHalfPage300(width) && bannerType === BannerType.JP_FRONTPAGE_FULL_GRID_HALF_PAGE_300) ||
            (LayoutHelper.canShowSectionHalfPage160(width) && bannerType === BannerType.SECTION_HALF_PAGE_160) ||
            (LayoutHelper.canShowSectionHalfPage300(width) && bannerType === BannerType.SECTION_HALF_PAGE_300) ||
            (LayoutHelper.canShowArticleHalfPage160(width) && LayoutHelper.canShowArticeHalfpageHeight(height) && bannerType === BannerType.ARTICLE_HALF_PAGE_160) ||
            (LayoutHelper.canShowArticleHalfPage300(width) && LayoutHelper.canShowArticeHalfpageHeight(height) && bannerType === BannerType.ARTICLE_HALF_PAGE_300)
    }

    public static setupGoogleTagEventHandling(): void {
        window.googletag.cmd.push(() => {
            window.googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                const bannerElement = document.getElementById(event.slot.getSlotElementId());
                if (event.isEmpty) {
                    console.log("No ad recieved for " + bannerElement.id)
                    if (bannerElement.id.includes('topscroll')) {
                        bannerElement?.parentElement?.classList.add('collapse');
                    } else {
                        bannerElement?.parentElement?.remove();
                    }
                } else {
                    bannerElement?.classList.add('annonceTag');
                    bannerElement?.parentElement?.classList.remove('placeholder_active');
                }
            });
        })
    }
}

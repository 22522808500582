export const breakpoints = {
  lg: 1024,
  xl: 1280,
  xxl: 1500,
}
export const jpFrontpageBreakpoints = {
  md: 1024,
  lg: 1280,
  xl: 1600,
  xxl: 1880,
}

export const articleHalfPageBannersShowBreakpoints = {
    halfPage160: 1315,
    halfPage300: 1580,
    height: 624
}

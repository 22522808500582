import { Controller } from "../../../../../../jp-assets/node_modules/stimulus";
import { application } from "../../../../../../jp-assets/src/ts/stimulus";
import { IPopup } from "../../../popup/popup_controller";

class GiveErrorController extends Controller {
    genericErrorElement: HTMLElement;
    genericErrorController: IPopup;

    connect() {
        if(document.body.id == "frontpage") {

        } else {
            this.getControllers().catch((error) => console.error(error));
        }
    }

    okButton() {
        this.genericErrorController.close();
    }

    reloadButton() {
        window.location.reload();
    }

    private getGenericErrorController(): Promise<IPopup> {
        return new Promise((resolve, reject) => {
            this.genericErrorElement = document.querySelector(
                "[data-popup-name='give-generic-error-popup']"
            );
            if (this.genericErrorElement) {
                this.genericErrorController =
                    this.application.getControllerForElementAndIdentifier(
                        this.genericErrorElement,
                        "popup"
                    );
                resolve(this.genericErrorController);
            } else {
                reject(new Error("No popup controller found"));
            }
        });
    }
    private getControllerAgainAfterTimeout(timeout: number): Promise<any> {
        const delay = new Promise((resolve, reject) => {
            setTimeout(resolve, timeout);
        });
        return delay.then(() => this.getControllers());
    }
    // Wait for all Stimulus controllers to load
    private getControllers(): Promise<any> {
        return Promise.all([this.getGenericErrorController()]).then(
            (controllers: any[]) => {
                if (controllers.includes(null)) {
                    return this.getControllerAgainAfterTimeout(200);
                } else {
                    return controllers;
                }
            }
        );
    }
}

export default GiveErrorController;

application.register("give-error", GiveErrorController);

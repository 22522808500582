import { Controller } from "../../../../../../../jp-assets/node_modules/stimulus"
import { application } from "../../../../../../../jp-assets/src/ts/stimulus";
import { ISaveArticleMessage } from "../button/save_article_message_controller";

export interface ISaveArticleAnimation extends Controller {
    addAnimation?: () => void
}

class SaveArticleAnimationController extends Controller implements ISaveArticleAnimation {
    messageElement: HTMLElement;
    messageController: ISaveArticleMessage;
    numberOfTimes: number;
    animationImage: HTMLElement;

    connect() {
        this.numberOfTimes = 0
        this.getController()
    }

    getController() {
        this.messageElement = document.querySelector("[data-controller='save-article-message']")
        this.messageController = this.application.getControllerForElementAndIdentifier(
            this.messageElement,
            "save-article-message")

        // Try to find controller again, up until 40 seconds has passed
        if (this.messageController == null && this.numberOfTimes < 200) {
            setTimeout(() => {
                this.numberOfTimes++
                this.getController()
            }, 200);
        }
    }

    addAnimation() {
        const articleTopImage = document.querySelector("[data-article-top-image]")
        const articleTopImageUrl = articleTopImage?.getAttribute("data-article-top-image")

        if (articleTopImageUrl) {
            this.animationImage = this.element.querySelector(".c-save-article-button-image")
            this.animationImage.setAttribute("src", articleTopImageUrl)
            this.animationImage.style.display = "block"
        } else {
            this.animationImage = this.element.querySelector(".c-save-article-button-image-placeholder")
            this.animationImage.style.display = "flex"
        }

        // Timeout to wait for the animation 1200ms to finish with 100 ms delay
        setTimeout(() => {
            this.element.classList.add("c-save-article-button-animation")
            this.animationImage.style.display = "none"
            this.messageController.messageAnimation("Artiklen er gemt på din læseliste")

            // Timeout to wait for the animation 300ms to finish
            setTimeout(() => {
                this.element.classList.remove("c-save-article-button-animation")
            }, 300)
        }, 1300)
    }
}

export default SaveArticleAnimationController;

application.register("save-article-animation", SaveArticleAnimationController);
